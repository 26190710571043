import { Avatar, Image } from "antd";
import axios from "axios";
import cn from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";

const ChatDetails = ({ refetch }): ReactElement => {
  const { token, chatInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const loader = useRef(null);
  const [page, setPage] = useState<any>(0);
  const chatId = chatInfo.id;
  const [messages, setMessage] = useState<any>([]);
  const [singleChatInfo, setSingleChatInfo] = useState<any>({
    loading: false,
    data: null,
  });

  useEffect(() => {
    const intervalCall = setInterval(() => {
      fetchChatDetails();
    }, 10000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [chatId]);

  const fetchChatDetails = useCallback(() => {
    if (chatId) {
      try {
        setSingleChatInfo({ loading: true, data: undefined });
        axios
          .get(
            `${process.env.REACT_APP_NOTIFICATION_API}/chat/details?chatId=${chatId}&page=${page}&limit=20`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              const message = [...(data?.data?.chatHistories || [])];

              setMessage((prevMessage) => [
                ...(message || []),
                ...(page === 0 ? [] : prevMessage || []),
              ]);

              setSingleChatInfo({
                loading: false,
                data: data?.data,
              });
            } else {
              setSingleChatInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleChatInfo({ loading: false, data: null });
            console.log(err);
          });
      } catch (error) {
        setSingleChatInfo({ loading: false, data: null });
        console.log(error, "error");
      }
    }
  }, [page, chatId]);

  useEffect(() => {
    if (chatId) {
      setPage(0);
    }
  }, [chatId, refetch]);

  useEffect(() => {
    fetchChatDetails();
  }, [fetchChatDetails, chatId, refetch]);

  useEffect(() => {
    if (page === 0) {
      loader?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <React.Fragment>
      <div className="content-body" style={{ maxHeight: "75vh" }}>
        <div className="bg-slate-100">
          <div className="flex-1 p:2 sm:p-6 justify-between flex flex-col h-screen">
            <div
              id="messages"
              className="flex flex-col gap-1 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
            >
              {singleChatInfo?.data?.totalElements !== messages?.length && (
                <span
                  onClick={() => setPage(page + 1)}
                  className="cursor-pointer text-center mb-4 text-xs"
                >
                  Load More...
                </span>
              )}

              {messages?.map((data, i) =>
                i === messages?.length - 1 ? (
                  <div className="chat-message" key={i} ref={loader}>
                    <div
                      className={cn(`flex items-end justify-end`, {
                        ["flex-row-reverse"]: data?.userInfo?.type === "USER",
                      })}
                    >
                      <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-0">
                        <div>
                          <span
                            className={cn(
                              `px-4 py-2 rounded-lg inline-block shadow max-w-xs text-left overflow-hidden`,
                              {
                                ["!bg-white text-gray-700 rounded-bl-none"]:
                                  data?.userInfo?.type === "USER",
                                //  ||
                                // data?.type === "PRODUCT" ||
                                // data?.type === "IMAGE",
                                ["rounded-br-none bg-blue-100 text-gray-800 shadow-md text-right"]:
                                  data?.userInfo?.type === "MERCHANT",
                              }
                            )}
                          >
                            {data?.type === "PRODUCT" ? (
                              <div className="rounded-lg flex flex-col justify-center">
                                <div className="">
                                  <Image
                                    src={
                                      JSON.parse(data?.message)?.productImage
                                    }
                                    width={250}
                                  />
                                </div>
                                <span className="font-medium text-lg">
                                  {JSON.parse(data?.message)?.name}
                                </span>
                              </div>
                            ) : data?.type === "IMAGE" ? (
                              <Image src={data?.message} width={200} />
                            ) : (
                              data?.message
                            )}
                            <small
                              className={cn(
                                `text-capitalize text-dark block font-light`,
                                {
                                  ["font-weight-bold text-danger"]:
                                    moment().diff(data?.createdAt, "minutes") >=
                                    60,
                                }
                              )}
                            >
                              {moment(data?.createdAt).format("lll")}
                            </small>
                          </span>
                        </div>
                      </div>
                      <Avatar
                        src={data?.userInfo?.profilePicture}
                        alt={data?.userInfo?.name}
                        className="w-6 h-6 rounded-full order-2"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="chat-message" key={i}>
                    <div
                      className={cn(`flex items-end justify-end`, {
                        ["flex-row-reverse"]: data?.userInfo?.type === "USER",
                      })}
                    >
                      <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-0">
                        <div>
                          <span
                            className={cn(
                              `px-4 py-2 rounded-lg inline-block shadow max-w-xs text-left overflow-hidden`,
                              {
                                ["!bg-white text-gray-700 rounded-bl-none"]:
                                  data?.userInfo?.type === "USER",
                                //  ||
                                // data?.type === "PRODUCT" ||
                                // data?.type === "IMAGE",
                                ["rounded-br-none bg-blue-100 text-gray-800 shadow-md text-right"]:
                                  data?.userInfo?.type === "MERCHANT",
                              }
                            )}
                          >
                            {data?.type === "PRODUCT" ? (
                              <div className="rounded-lg flex flex-col justify-center">
                                <div className="">
                                  <Image
                                    src={
                                      JSON.parse(data?.message)?.productImage
                                    }
                                    width={250}
                                  />
                                </div>
                                <span className="font-medium text-lg">
                                  {JSON.parse(data?.message)?.name}
                                </span>
                              </div>
                            ) : data?.type === "IMAGE" ? (
                              <Image src={data?.message} width={200} />
                            ) : (
                              data?.message
                            )}
                            <small
                              className={cn(
                                `text-capitalize text-dark block font-light`,
                                {
                                  ["font-weight-bold text-danger"]:
                                    moment().diff(data?.createdAt, "minutes") >=
                                    60,
                                }
                              )}
                            >
                              {moment(data?.createdAt).format("lll")}
                            </small>
                          </span>
                        </div>
                      </div>
                      <Avatar
                        src={data?.userInfo?.profilePicture}
                        alt={data?.userInfo?.name}
                        className="w-6 h-6 rounded-full order-2"
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
ChatDetails.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ChatDetails;
