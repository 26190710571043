import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  AutoComplete,
  Avatar,
  Button,
  Empty,
  Form,
  Input,
  Rate,
  Select,
  Spin,
  Popconfirm,
  Dropdown,
  Menu,
  Modal,
  Switch,
} from "antd";

import axios from "axios";
import BreadCrumb from "../Layouts/Breadcrumb";
import AddBtn from "../common/AddBtn";
import { responseNotification } from "../../utils/notify";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import LoaderFull from "../common/LoaderFull";
import { debounce } from "lodash";
import _ from "lodash";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { LOGIN_SUCCESS } from "../../redux/auth/authType";
import { ShopStatus } from "../../utils";

const { confirm } = Modal;

const ShopList = (): ReactElement => {
  const { type, token, merchantId } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [key, setKey] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);

  const [shopData, setShopData] = useState<any>({
    loading: false,
    data: null,
  });

  const showPopconfirm = (mobileNumber: any) => {
    setVisiblePopconfirm(mobileNumber);
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };

  const [shopOptions, setShopOptions] = useState({
    loading: false,
    list: [],
  });
  const isDeleted = "false";

  const getShops = useCallback(async () => {
    setShopData({ loading: true, data: null });
    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUrl}/merchant/all-shops-by-merchantId?type=${type}&merchantId=${merchantId}` +
          (key ? `&key=${key}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (page
            ? `&page=${
                page == shopData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setShopData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setShopData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [limit, page, merchantId, key, isActive, isDeleted]);

  const getShopOptions = useCallback(
    async (key: any) => {
      setShopOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/shop/admin/search?type=${type}&page=0&limit=20` +
            (merchantId ? `&merchantId=${merchantId}` : ``) +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.shops?.map((shop: { name: any }) => ({
              label: shop.name,
              value: shop.name,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Shops: Error", err);
        });
    },
    [type, merchantId]
  );

  const fetchShopDetails = useCallback((res: any) => {
    if (res) {
      try {
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/details`,
            {
              headers: {
                Authorization: `Bearer ${res?.token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                  token: res?.token,
                  // mobileNumber: res?.mobileNumber,
                  userType: res?.usertype || "MERCHANT",
                  shopInfo: data?.data?.shop,
                  shopId: data?.data?.shop?.id,
                  iat: new Date(),
                  type: data?.data?.shop?.type || "JC_COMMERCE",
                },
              });

              navigate("/");
            } else {
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  }, []);

  const onSetDefault = async (mobileNumber: any, shopId) => {
    if (mobileNumber) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/merchant/switch-to-shop`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          shopMobileNumber: mobileNumber,
          merchantId: merchantId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(res.message, "success");
            fetchShopDetails(res);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  //

  const onStatusChange = async (
    id?: any,
    val?: any,
  ) => {
    setConfirmLoading(id);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/shop`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setConfirmLoading(false);
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getShops();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  function showConfirm(id: any, status: any) {
    confirm({
      title: `Do you Want to change Status "${status}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onStatusChange(id, status);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "Change Status",
    });
  }

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "shop") getShopOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getShopOptions]);

  useEffect(() => {
    getShops();
  }, [getShops]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setIsActive("");
  };

  return (
    <>
      <BreadCrumb
        title={type == "JC_FOOD" ? "Restaurant List" : "Shop List"}
        subTitle={`${
          shopData?.data?.totalElements || shopData?.data?.shops?.length || 0
        } Shop(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,

          // isAllowedService(`Shops Create`) && (
          <AddBtn
            onClick={() => {
              navigate("/shops/create");
            }}
          />,
          // ),
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search">
              <AutoComplete
                style={{ width: 250 }}
                onSearch={(e) => handleSearch(e, "shop")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val.toString())
                }
                options={shopOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  shopOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={shopOptions.loading}
                />
              </AutoComplete>
            </Form.Item>

            <Form.Item
              name="shop_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && <LoaderFull />}
              {shopData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Shop</th>
                      <th scope="col">Contact Number</th>
                      <th scope="col">Ratings</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {shopData?.data?.shops?.length ? (
                      shopData?.data?.shops?.map((shop: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100 cursor-pointer"
                          key={index}
                        >
                          <td
                            onClick={() =>
                              navigate(`/shops/${shop?.id}/details`)
                            }
                            className="flex gap-2 items-center"
                          >
                            <Avatar
                              size={45}
                              src={shop?.logo}
                              className="border"
                            />
                            <span className="font-medium text-gray-500 ml-2 flex flex-col">
                              {shop?.name}
                              <span>
                                {type == "JC_FOOD" && (
                                  <div className="flex">
                                    <span
                                      className={classNames(
                                        `text-xs font-light pl-1 pr-2 py-0.5 leading-3 rounded-full border col-auto flex items-center gap-1`,
                                        shop?.status == "Close"
                                          ? `bg-red-100 text-red-600`
                                          : shop?.status == "Busy"
                                          ? `bg-yellow-200 text-yellow-600`
                                          : `bg-green-100 text-green-600`
                                      )}
                                    >
                                      <ClockCircleOutlined />
                                      <small>
                                        {shop?.status?.toUpperCase()}
                                      </small>
                                    </span>
                                  </div>
                                )}
                              </span>
                            </span>
                          </td>

                          <td>{shop?.mobileNumber?.replace("+88", "")}</td>

                          <td>
                            <Rate value={shop?.rating || 1} disabled />{" "}
                            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                              {shop?.numberOfRating || 0}
                            </span>
                          </td>

                          {/* {isAllowedService(`Shops Edit`) && !inCampaign && ( */}
                          <td>
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              defaultChecked={shop?.isActive}
                              onChange={(val:any, e:any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onStatusChange(shop?.id, val);
                              }}
                              className="mr-1"
                              loading={
                                confirmLoading && shop?.id === confirmLoading
                              }
                              // disabled={shop?.hub?.deleted}
                            />
                          </td>

                          <td>
                            <div className="flex flex-grow gap-2">
                              {/* <Popconfirm
                                placement="left"
                                title="Do you want to login this store?"
                                visible={
                                  shop?.mobileNumber === visiblePopconfirm
                                }
                                onConfirm={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onSetDefault(shop?.mobileNumber, shop?.id);
                                }}
                                okButtonProps={{
                                  loading: confirmLoading,
                                  className: "bg-blue-400",
                                  shape: "round",
                                }}
                                cancelButtonProps={{
                                  danger: true,
                                  type: "primary",
                                  shape: "round",
                                }}
                                okText="Yes"
                                cancelText="Not now"
                                onCancel={handleCancel}
                              >
                                <Button
                                  className="bg-white"
                                  type="dashed"
                                  shape="round"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    showPopconfirm(shop?.mobileNumber);
                                  }}
                                >
                                  Switch to Shop
                                </Button>
                              </Popconfirm> */}

                              <Link
                                title="Edit Product"
                                to={`/shops/${shop?.id}/edit`}
                                // target="_blank"
                                className="py-2 px-4 rounded-full m-0 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                              >
                                <EditOutlined />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...shopData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default ShopList;
