import {
  LOGIN_LOADING,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SIDEBAR_COLLAPSE,
  SET_SHOP_INFO,
  SET_MERCHANT_INFO,
  SET_CAMPAIGN_INFO,
  SET_DRIVER_INFO,
  SET_CHAT_INFO,
  SET_USER_INFO,
  LOGOUT,
  SET_BRAND_INFO,
  SET_FLIGHT_INFO,
} from "./authType";

const initialState = {
  name: "",
  email: "",
  profilePicture: "",
  role: "",
  exp: null,
  error: false,
  success: false,
  loading: false,
  type: null,
  token: null,
  sidebarCollapse: false,
  merchantId: null,
  shopId: null,
  chatInfo: {
    id: "",
    customerProfilePicture: "",
    customerName: "",
  },
  campaignInfo: {
    id: "",
    name: "",
    appsImg: "",
    webImg: "",
    status: null,
    type: "",
    campaignType: "",
  },
  merchantInfo: {
    id: "",
    name: "",
    balance: "",
    profilePicture: "",
    mobileNumber: "",
    email: "",
    state: "",
    status: "",
    merchantId: "",
  },
  shopInfo: {
    id: "",
    name: "",
    type: "",
    mobileNumber: "",
    rating: null,
    logo: "",
    banner: "",
    status: "",
    hubId: "",
    deleted: "",
  },
  driverInfo: {
    driverId: "",
    image: "",
    name: "",
    rating: null,
    balance: "",
    mobileNumber: "",
    status: "",
  },
  customerInfo: {
    fullName: "",
    profilePicture: "",
    balance: "",
    rating: null,
    numberOfRating: null,
    mobileNumber: "",
    status: "",
    id: "",
  },

  brandInfo: {
    id: "",
    name: "",
    type: "",
    logo: "",
    bannerImage: "",
    status: null,
  },
};

const authRedcer = (
  state = initialState,
  action = { type: "", payload: {} }
) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      state.loading = true;
      return state;
    }

    case LOGIN_SUCCESS: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }

    case LOGIN_LOADING: {
      return state;
    }

    case SIDEBAR_COLLAPSE: {
      state = { ...state, ...action.payload };
      return state;
    }

    case SET_SHOP_INFO: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }

    case SET_MERCHANT_INFO: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }

    case SET_CAMPAIGN_INFO: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }

    case SET_DRIVER_INFO: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }

    case SET_CHAT_INFO: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }

    case SET_USER_INFO: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }

    case SET_BRAND_INFO: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }

    case SET_FLIGHT_INFO: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }

    case LOGOUT: {
      state = {
        ...state,
        token: null,
        merchantInfo: null,
        merchantId: null,
        shopInfo: null,
        shopId: null,
        ...action.payload,
      };
      return state;
    }

    default: {
      state.loading = false;
      return state;
    }
  }
};

export default authRedcer;
