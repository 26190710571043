import {
  BarcodeOutlined,
  CopyOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import axios from "axios";
import React, {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Checkbox,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  Rate,
  Select,
  Space,
  Spin,
  Tooltip,
  TreeSelectProps,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import memberShipStyles from "./Profile.module.css";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import QuickUpdate from "../Product/QuickUpdate";
import moment from "moment";
import UserHeader from "./UserHeader";
import { isAllowedService } from "../../utils/services";
import {
  AddBtn,
  Loader,
  LoaderFull,
  Pagination,
  TreeSelectTwo,
} from "../common";

const GlobalProductList = (): ReactElement => {
  const router = useLocation();
  const { type, token, shopId } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [key, setKey] = useState("");
  const [categoryId, setCategoryId] = useState<any>();
  const [brandId, setBrandId] = useState<any>();
  const [isActive, setIsActive] = useState<any>(true);
  const [showSearch, setShowSearch] = useState(true);
  const [selectedProductForEdit, setSelectedProductForEdit] =
    useState<any>(undefined);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [barCode, setBarCode] = useState(
    getParamValue(router.search, "barCode")
  );
  // --------------------------------
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [error, setError] = useState("");
  const [selectedProductBarcode, setSelectedProductBarcode] = useState<any>([]);
  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });
  // --------------------------------

  const [productData, setProductData] = useState<any>({
    loading: false,
    data: null,
  });
  const [memberShipLevelData, setMemberShipLevelData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [productOptions, setProductOptions] = useState({
    loading: false,
    list: [],
  });

  const [brandOptions, setBrandOptions] = useState({
    loading: false,
    list: [],
  });

  const getMemberShipLavel = useCallback(async () => {
    setMemberShipLevelData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUrl}/membership/level?userType=USER` +
          (page
            ? `&page=${
                page == productData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setMemberShipLevelData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setMemberShipLevelData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [type, page, limit]);

  const getProductByBarcodeOptions = useCallback(
    async (bCode: any) => {
      setProductBarcodeOptions({ loading: false, list: [] });

      setProductBarcodeOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;

      const res = await axios.get(
        `${encodedUri}/global-product?` +
          `page=${page || 0}` +
          `&limit=${limit || 16}` +
          // `&isDeleted=${false}` +
          (bCode ? `&barCode=${bCode}` : ``) +
          (key ? `&key=${key}` : ``) +
          (type ? `&type=${type}` : ``) +
          (isActive !== "" ? `&isActive=${isActive}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProductBarcodeOptions({
        loading: false,
        list: res?.data?.keys?.map((product: { name: any; barCode: any }) => {
          return {
            value: product?.barCode,
            label: product?.name,
          };
        }),
      });
    },
    [page, limit, key, type, isActive, status]
  );

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      return axios
        .get(
          `${encodedUri}/global-product?type=${type}&page=0&limit=20` +
            (key ? `&key=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.products?.map((product: { name: any }) => ({
              label: product.name,
              value: product.name,
            })),
          });
        })
        .catch((err) => {
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [type]
  );

  const getBrandsOptions = useCallback(async (key?: string) => {
    setBrandOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/brand/search?type=${type}&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setBrandOptions({
      loading: false,
      list: res?.data?.brands?.map((brand: any) => ({
        label: brand.name,
        value: brand.id,
      })),
    });
  }, []);

  // ************************
  const onChangeCheckbox = (e: any) => {
    setSelectedProductBarcode((prevVal: any) => {
      const newVal = e;
      let newArr = Array.from(prevVal);
      const found = prevVal.find((item: any) => item === newVal);
      if (!found) {
        newArr.push(newVal);
      } else {
        newArr = newArr.filter((item) => item !== newVal);
      }
      return newArr;
    });

    setIndeterminate(
      !!checkedList.length &&
        checkedList.length <= selectedProductBarcode.length
    );
    setCheckAll(checkedList.length === selectedProductBarcode.length);
  };

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "product") getProductOptions(value);
        else if (field === "brand") getBrandsOptions(value);
        else if (field === "productByBarcode")
          getProductByBarcodeOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getProductOptions, getBrandsOptions, getProductByBarcodeOptions]);

  useEffect(() => {
    getMemberShipLavel();
  }, [getMemberShipLavel]);

  useEffect(() => {
    if (showSearch) {
      getProductOptions("");
      getBrandsOptions("");
      getProductByBarcodeOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    if (visible) {
      getProductByBarcodeOptions("");
    }
  }, [visible]);

  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setCategoryId("");
    setBrandId("");
    setBarCode("");
    setRange({
      from: null,
      to: null,
    });
    setIsActive(undefined);
  };

  // ----------------------------
  // ----------------------------

  //
  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      barCodes: selectedProductBarcode,
      shopIds: [shopId],
    };

    await fetch(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/product/add-from-global-product`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(
            "Product Cloned Successfully in Selected Shop(s)",
            "success"
          );
          // getShopsOptions();
          info(res?.productUpdateResponseRestList);

          setVisible(!visible);
          form.resetFields();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const onCheckAllChange = (e: any) => {
    setSelectedProductBarcode(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setSelectedProductForEdit(undefined);
  };

  function info(alreadyExistsData: any) {
    Modal.info({
      title: "Clone Info",
      content: (
        <>
          {alreadyExistsData?.map((data, i) => (
            <div key={i} className="mb-3">
              <p className="text-gray-800 font-semibold">
                Added {data?.newAdded} item(s) and already exists{" "}
                {data?.alreadyExists} item(s) in{" "}
                <span className="font-bold text-red-900">{data?.shopName}</span>
              </p>
              <ol className="text-gray-500">
                {data?.alreadyExistsProductName?.map((product, index) => (
                  <li key={index}>
                    {index + 1}. {product}
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </>
      ),
      width: 600,
      onOk() {},
      okText: "GOT IT",
      okButtonProps: { danger: true },
    });
  }
  // ----------------------------

  return (
    <>
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 membershipMainDiv">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              <div className={memberShipStyles.memberShipTitle}>
                <h1 className={memberShipStyles.memberShipH1}>
                  jachai.com Membership
                </h1>
                <p>Explore US Tk 100 of wih a new seller and more benefits</p>
              </div>

              <Form
                name="control-hooks"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onSubmit}
                form={form} // like ref
                layout="vertical"
                autoComplete="off"
              >
                <div className="flex float-left w-full">
                  <Card className={memberShipStyles.memberShipCard}>
                    <p>Membership Type</p>
                    {memberShipLevelData?.data?.membershipLevels?.length ? (
                      memberShipLevelData?.data?.membershipLevels?.map(
                        (membershipLevel: any, index: any) => (

                    <div className="flex">
                      <Radio.Group>
                        <Space direction="vertical">
                          <Radio value={membershipLevel?.id}>
                            {membershipLevel?.name} ({membershipLevel?.amount || 0})
                            <Tooltip
                              placement="bottom"
                              title={"Seller Warehouse Address"}
                            >
                              <InfoCircleOutlined className="px-2 -mt-2" />
                            </Tooltip>
                          </Radio>
                          
                        </Space>
                      </Radio.Group>
                    </div>
                         )
                         )
                       ) : (
                        <Empty />
                       )}

                  </Card>
                </div>

                <div className="flex float-left w-full">
                  <div className="buttons-container mt-10">
                    <Button
                      //type="ghost"
                      htmlType="button"
                      onClick={() => {
                        form?.resetFields();
                      }}
                      className="reset-submit-btn text-center mr-2"
                    >
                      Cancel
                    </Button>

                    <Button
                      disabled={loading || !!error}
                      loading={(loading ? "loading" : undefined) as any}
                      type="primary"
                      htmlType="submit"
                      className="add-submit-btn text-center mr-2"
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalProductList;
