import axios from "axios";
import { useCallback, useEffect, useState, useRef } from "react";
import {
  AutoComplete,
  Avatar,
  Badge,
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Spin,
} from "antd";
import { getParamValue } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import moment from "moment";
import { ImageUploader } from "../common";
import cn from "classnames";
import ChatDetails from "./Details";
import { responseNotification } from "../../utils/notify";
import { SET_CHAT_INFO } from "../../redux/auth/authType";
import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";

const ChatList = () => {
  const { type, token, shopInfo, chatInfo } = useSelector(
    (state) => (state as any)?.authReducer
  );

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [status, setStatus] = useState("");
  const [limit, setLimit] = useState(16);
  const [baseOrderId, setBaseOrderId] = useState<any>();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<any>(false);
  const [openProductModal, setOpenProductModal] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [products, setProducts] = useState<any>();
  const [productOptions, setProductOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [chatData, setChatData] = useState<any>({
    loading: false,
    data: null,
  });

  const [shopOptions, setShopOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const getShopChat = useCallback(async () => {
    setChatData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_NOTIFICATION_API}`;
    axios
      .get(
        `${encodedUri}/chat/history/by-shop?page=${page || 0}&limit=${
          limit || 16
        }` +
          (status ? `?status=${status}` : ``) +
          (baseOrderId ? `&orderId=${baseOrderId}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChatData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setChatData({ loading: false, data: [] });
        console.error("Chat Type: Error", err);
      });
  }, [page, limit, status, baseOrderId]);

  const getShopOptions = useCallback(
    async (getOrderId) => {
      setShopOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_NOTIFICATION_API}`;
      return axios
        .get(
          `${encodedUri}/admin/order?` +
            (getOrderId
              ? `baseOrderId=${getOrderId}`
              : `` + getOrderId
              ? `orderId=${getOrderId}`
              : ``) +
            `&page=${page || 0}` +
            (type ? `&type=${type}` : ``) +
            (status ? `&status=${status}` : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOptions({
            loading: false,
            list: res.data?.orders?.map((order, index) => ({
              key: index,
              label: order.baseOrderId,
              value: order.baseOrderId,
            })),
          });
        })
        .catch((err) => {
          setShopOptions({ loading: false, list: [] });
          console.error("Categories: Error", err);
        });
    },
    [limit, page, status, type]
  );

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "order") getShopOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getShopOptions]);

  const [selectedChat, setSelectedChat] = useState<any>();

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data) => {
    setLoading(true);

    const readyData = data && {
      message: generatedImgUrl
        ? generatedImgUrl
        : selectedProduct
        ? JSON.stringify(selectedProduct)
        : data.message,
      partnerId: chatInfo?.userInfo?.id,
      type: generatedImgUrl ? "IMAGE" : selectedProduct ? "PRODUCT" : "TEXT",
      // url: "string",
    };

    await fetch(`${process.env.REACT_APP_NOTIFICATION_API}/chat/by-merchant`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...readyData,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          // fetchChatDetails(selectedChat?.id);
          form.resetFields();
          onClose();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const getProductOptions = useCallback(
    async (key: any) => {
      setProductOptions({ loading: true, list: [] });
      setProducts(undefined);
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      let url =
        `?type=${type}&page=0&limit=5&isActive=${true}&shopId=${shopInfo?.id}` +
        (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/admin/product/v2${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductOptions({
            loading: false,
            list: res.data?.products?.map((product) => ({
              label: product.name,
              value: product.id,
            })),
          });
          setProducts(res.data?.products);
        })
        .catch((err) => {
          setProducts(undefined);
          setProductOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [type, page, limit]
  );

  useEffect(() => {
    getShopOptions("");
    getProductOptions(``);
  }, [getShopOptions, getProductOptions]);

  useEffect(() => {
    getShopChat();
  }, [getShopChat]);

  // setInterval(() => {
  //   getShopChat();
  // }, 600000);

  useEffect(() => {
    if (selectedChat) {
      // fetchChatDetails(selectedChat?.id);
      dispatch({
        type: SET_CHAT_INFO,
        payload: {
          chatInfo: selectedChat,
        },
      });
    }
  }, [selectedChat]);

  const onClose = () => {
    // fetchChatDetails(selectedChat?.id);
    setShowModal(false);
    setOpenProductModal(false);
    setSelectedProduct(null);
    setGeneratedImgUrl(null);
  };
  return (
    <>
      <div className="py-2"></div>

      <div
        className={cn(
          styles.contentWrapper__,
          "h-fit bg-white rounded-xl shadow-xl border mb-12"
        )}
      >
        <div className="content-body_ sticky top-4 ">
          <div className="mx-auto">
            <div className="h h-fit">
              <div className="flex">
                <div className="w-1/3 flex flex-col">
                  <div className="py-2 px-3 bg-grey-lighter flex flex-row justify-between items-center">
                    <div>
                      <Avatar src={shopInfo?.logo} size={50} />
                    </div>

                    <div className="flex">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            fill="#727A7E"
                            d="M12 20.664a9.163 9.163 0 0 1-6.521-2.702.977.977 0 0 1 1.381-1.381 7.269 7.269 0 0 0 10.024.244.977.977 0 0 1 1.313 1.445A9.192 9.192 0 0 1 12 20.664zm7.965-6.112a.977.977 0 0 1-.944-1.229 7.26 7.26 0 0 0-4.8-8.804.977.977 0 0 1 .594-1.86 9.212 9.212 0 0 1 6.092 11.169.976.976 0 0 1-.942.724zm-16.025-.39a.977.977 0 0 1-.953-.769 9.21 9.21 0 0 1 6.626-10.86.975.975 0 1 1 .52 1.882l-.015.004a7.259 7.259 0 0 0-5.223 8.558.978.978 0 0 1-.955 1.185z"
                          ></path>
                        </svg>
                      </div>
                      <div className="ml-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            opacity=".55"
                            fill="#263238"
                            d="M19.005 3.175H4.674C3.642 3.175 3 3.789 3 4.821V21.02l3.544-3.514h12.461c1.033 0 2.064-1.06 2.064-2.093V4.821c-.001-1.032-1.032-1.646-2.064-1.646zm-4.989 9.869H7.041V11.1h6.975v1.944zm3-4H7.041V7.1h9.975v1.944z"
                          ></path>
                        </svg>
                      </div>
                      <div className="ml-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            fill="#263238"
                            fillOpacity=".6"
                            d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="py-2 px-2 bg-grey-lightest">
                    <Input
                      type="text"
                      className="w-full px-2 py-2 text-sm rounded-lg"
                      placeholder="Search or start new chat"
                    />
                  </div>

                  <div className="bg-grey-lighter flex-1 items-center overflow-auto">
                    {chatData?.data?.chats?.length ? (
                      chatData?.data?.chats?.map((chat: any, index: any) => (
                        <div
                          className={cn(
                            `px-3 flex items-center bg-grey-light cursor-pointer border-b border-grey-lighter py-2`,
                            {
                              ["bg-slate-50"]: !chat?.isSeenByShop,
                              ["bg-slate-200"]: chatInfo?.id === chat?.id,
                            }
                          )}
                          key={index}
                          onClick={() => {
                            setSelectedChat(chat);
                          }}
                        >
                          <Badge dot={!chat?.isSeenByShop}>
                            <Avatar
                              src={chat?.userInfo?.profilePicture}
                              size={50}
                            />
                          </Badge>
                          <div className="ml-4 flex  justify-between w-full">
                            <div className="flex-1 items-bottom justify-between">
                              <p className="text-gray-700 font-semibold mb-0 capitalize">
                                {chat?.userInfo?.name}
                              </p>
                              <p className="text-gray-500 text-sm">
                                {chat?.type === "PRODUCT"
                                  ? "Product..."
                                  : chat?.type === "IMAGE"
                                  ? "Image..."
                                  : chat?.message?.slice(0, 20)}
                              </p>
                            </div>

                            <p className="text-xs text-grey-darkest font-light text-right">
                              <span
                                className={cn(
                                  `text-slate-300 flex items-end justify-end`,
                                  {
                                    ["text-blue-300"]: chat?.isSeenByUser,
                                  }
                                )}
                              >
                                <svg
                                  viewBox="0 0 16 11"
                                  height="11"
                                  width="16"
                                  className="text-right"
                                  preserveAspectRatio="xMidYMid meet"
                                  fill="none"
                                >
                                  <path
                                    d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </span>
                              <span className="name">
                                <small
                                  className={`text-capitalize ${
                                    moment().diff(chat?.createdAt, "minutes") >=
                                    60
                                      ? "font-weight-bold text-danger"
                                      : "text-dark"
                                  }`}
                                >
                                  {moment(chat?.createdAt).fromNow()}
                                </small>{" "}
                                <br />
                                {moment(chat?.createdAt).format("ll")}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </div>
                </div>

                <div className="w-2/3 border-l flex flex-col">
                  <div className="py-2 px-3 bg-grey-lighter flex flex-row justify-between items-center border-b">
                    <div className="flex items-center">
                      <div>
                        <Avatar
                          src={chatInfo?.userInfo?.profilePicture}
                          size={50}
                        />
                      </div>
                      <div className="ml-4">
                        <p className="text-grey-darkest uppercase">
                          {chatInfo?.userInfo?.name}
                        </p>
                        {/* <p className="text-grey-darker text-xs mt-1">
                          {chatInfo?.userInfo?.mobileNumber?.replace("+88", "")}
                        </p> */}
                      </div>
                    </div>

                    <div className="flex">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            fill="#263238"
                            fillOpacity=".5"
                            d="M15.9 14.3H15l-.3-.3c1-1.1 1.6-2.7 1.6-4.3 0-3.7-3-6.7-6.7-6.7S3 6 3 9.7s3 6.7 6.7 6.7c1.6 0 3.2-.6 4.3-1.6l.3.3v.8l5.1 5.1 1.5-1.5-5-5.2zm-6.2 0c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z"
                          ></path>
                        </svg>
                      </div>

                      <div className="ml-6">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            fill="#263238"
                            fillOpacity=".5"
                            d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                          ></path>
                        </svg>
                      </div>
                      <div className="ml-6">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            fill="#263238"
                            fillOpacity=".6"
                            d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>

                  {chatInfo && (
                    <>
                      <ChatDetails refetch={onClose} />

                      <div className="border-t border-gray-200 px-4 py-3 sm:mb-0 sticky bottom-2">
                        <Form
                          name="control-hooks"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          className={styles.formStyles}
                          onFinish={onSubmit}
                          form={form} // like ref
                          layout="vertical"
                        >
                          <div className="relative flex">
                            {/* <span className="absolute inset-y-0 flex items-center">
                            <button
                              type="button"
                              className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6 text-gray-600"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                                ></path>
                              </svg>
                            </button>
                          </span> */}

                            <Form.Item
                              name="message"
                              rules={[
                                {
                                  required:
                                    !generatedImgUrl && !selectedProduct,
                                  message: "Name is Required!",
                                },
                              ]}
                              className="mb-0 w-full"
                            >
                              <Input
                                type="text"
                                placeholder="Write your message!"
                                className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-4 bg-gray-200 rounded-md py-3 z-0"
                              />
                            </Form.Item>

                            <div className="absolute right-0 items-center inset-y-0 hidden sm:flex z-20">
                              <button
                                onClick={() => setOpenProductModal(true)}
                                type="button"
                                className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  className="h-6 w-6 text-gray-600"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                onClick={() => setShowModal(true)}
                                type="button"
                                className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  className="h-6 w-6 text-gray-600"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                                  ></path>
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                                  ></path>
                                </svg>
                              </button>
                              {/* <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  className="h-6 w-6 text-gray-600"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  ></path>
                                </svg>
                              </button> */}
                              <Button
                                disabled={!chatInfo?.userInfo?.id}
                                htmlType="submit"
                                size="large"
                                className="inline-flex items-center justify-center rounded-lg px-4 py-6 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 hover:text-white focus:outline-none z-20"
                              >
                                <span className="font-bold">Send</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="h-6 w-6 ml-2 transform rotate-90"
                                >
                                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                                </svg>
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Upload File"
        open={showModal}
        okText="Send"
        onOk={(e) => {
          e.preventDefault();
          setStartUpload("Uploading");
        }}
        confirmLoading={loading}
      >
        <ImageUploader
          imgUrl={generatedImgUrl || ""}
          startUpload={startUpload}
          setStartUpload={(val: any) => setStartUpload(val)}
          setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
        />
      </Modal>

      <Modal
        title="Products"
        okText="Send"
        centered
        open={openProductModal}
        onCancel={onClose}
        width={"96%"}
        onOk={onSubmit}
        // okButtonProps={{
        //   // loading: true,
        //   disabled: !selectedProduct?.length,
        // }}
      >
        <div style={{ minHeight: 500 }}>
          <Form.Item name="search">
            <AutoComplete
              style={{ width: 300 }}
              onSearch={(val) => getProductOptions(val)}
              // onSelect={(val: { toString: () => SetStateAction<string> }) =>
              //   setKey(val.toString())
              // }
              options={productOptions?.list}
              defaultActiveFirstOption={false}
              notFoundContent={
                productOptions?.loading ? <Spin size="small" /> : null
              }
            >
              <Input.Search
                placeholder="Search by Name"
                // onSearch={(val) => setKey(val)}
                enterButton
                loading={productOptions.loading}
              />
            </AutoComplete>
          </Form.Item>

          <div className="grid grid-cols-5 gap-4 py-2">
            {products?.length ? (
              products?.map((product, i: any) => (
                <div className="shadow-md rounded-md" key={i}>
                  <div className="">
                    <img
                      src={product?.productImage}
                      alt=""
                      className="rounded-t-md w-full"
                    />
                  </div>
                  <div className="p-3">
                    <span className="font-semibold text-gray-600 line-clamp-2">
                      {product?.name || 324}
                    </span>

                    <div className="grid grid-cols-2 gap-3 mt-2">
                      {product?.variations
                        ?.slice(0, 3)
                        ?.map((variant: any, i: string) => (
                          <div
                            className={cn(
                              `shadow rounded-md bg-gray-50_ hover:bg-green-50 border border-gray-200 hover:border-green-200 p-2 flex flex-col text-slate-600 hover:text-green-700 relative cursor-pointer`,
                              {
                                ["bg-green-100 border-green-200 text-green-700"]:
                                  selectedProduct?.variationId ===
                                  variant?.variationId,
                              }
                            )}
                            key={i}
                            onClick={() => {
                              setSelectedProduct({
                                productImage: product?.productImage,
                                name: product?.name,
                                variationId: variant?.variationId,
                                variant: variant,
                              });
                            }}
                          >
                            {selectedProduct?.variationId ===
                              variant?.variationId && (
                              <div className="absolute right-1 top-0">
                                <CheckCircleOutlined />{" "}
                              </div>
                            )}

                            <span className="font-bold">
                              {variant?.variationName}
                            </span>
                            <span>Stock: {variant?.stock}</span>
                            <span>MRP: {variant?.price?.mrp}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Empty />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChatList;
