import ChatList from "../../components/Chat";
import MainLayout from "../../components/Layouts";

const ChatPage = () => {
  return (
    <MainLayout>
      <ChatList />
    </MainLayout>
  );
};

export default ChatPage;
