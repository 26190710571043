import React from "react";
import MainLayout from "../../components/Layouts";
import DeliveryShippingSettings from "../../components/DeliveryShipping/index";

const DeliveryShippingPage = () => {
  return (
    <MainLayout>
      <DeliveryShippingSettings />
    </MainLayout>
  );
};

export default DeliveryShippingPage;
