import { Button, Card, Form, Input, Select, Space, Switch } from "antd";
import { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import { useParams } from "react-router";
import React from "react";
import axios from "axios";
import { debounce } from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import { jcDayArray } from "../../utils";

const AddShopAddress = ({ visibleData, onCloseMethod }: any) => {
  const { type, shopInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const route = useParams();
  const shopID = shopInfo?.id;
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [shopData, setShopData] = useState<any>(undefined);
  const [deliveryZoneId, setDeliveryZoneId] = useState<any>("");
  const [countryId, setCountryId] = useState<any>(0);
  const [stateId, setStateId] = useState<any>(0);
  const [cityId, setCityId] = useState<any>(0);
  const [businessDay, setBusinessDay] = useState();
  const [pickupZoneType, setPickupZoneType] = useState("");
  const [zoneOptions, setZoneOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [countryOptions, setCountryOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [stateOptions, setStateOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const getCountryOptions = useCallback(async (key?: string) => {
    setCountryOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/admin/country-state/countries?isOperationEnabled=true&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCountryOptions({
      loading: false,
      list: res?.data?.countries?.map((country: any) => {
        return {
          value: country?.id,
          label: country?.name,
        };
      }),
    });
  }, []);

  const getStateOptions = useCallback(
    async (key?: string) => {
      setStateOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/states?countryId=${countryId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStateOptions({
        loading: false,
        list: res?.data?.states?.map((state: any) => {
          return {
            value: state?.id,
            label: state?.name,
          };
        }),
      });
    },
    [countryId]
  );

  const getCityOptions = useCallback(
    async (key?: string) => {
      setCityOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_RIDER_API}/admin/country-state/cities?stateId=${stateId}&page=0&limit=20` +
          (key ? `&key=${key}` : ``),

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCityOptions({
        loading: false,
        list: res?.data?.cities?.map((city: any) => {
          return {
            value: city?.id,
            label: city?.name,
          };
        }),
      });
    },
    [stateId]
  );

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      pickupZoneType: pickupZoneType,
      packagingTime: data.packagingTime,
      countryId: countryId,
      stateId: stateId,
      cityId: cityId,
      address: data.address,
      shopId: shopID,
      schedules: data?.businessDayes?.map(
        (scheduleList: any, scheduleListId: string) => ({
          businessDayName: scheduleList.businessDay,
          chargeBySchedules: scheduleList?.list?.map(
            (scheduleConfigList: any, scheduleConfigListId: string) => ({
              charge: scheduleConfigList.scheduleCharge,
              scheduleDeliveryTimeFrom:
                scheduleConfigList.scheduleDeliveryTimeFrom,
              scheduleDeliveryTimeTo: scheduleConfigList.scheduleDeliveryTimeTo,
            })
          ),
        })
      ),
    };

    if (shopData) {
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/pickup-zone/add-zone-v2`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: shopData?.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Zone Updated Successfully", "success");
            //form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/admin/pickup-zone/add-zone-v2`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Zone Create Successfully", "success");
            //form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setShopData(visibleData);
      // setDeliveryZoneId(visibleData?.bannerType);
    }
  }, [visibleData]);

  useEffect(() => {
    if (shopData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [shopData, form, visibleData]);

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "country") getCountryOptions(value);
          if (field === "state") getStateOptions(value);
          if (field === "city") getCityOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getCountryOptions,
    getStateOptions,
    getCityOptions,
    type,
  ]);

  // **********************************
  // Search Options
  // **********************************
  useEffect(() => {
    if (visibleData) {
      setShopData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    getCountryOptions();
    getStateOptions();
    getCityOptions();
  }, [getCountryOptions, getStateOptions, getCityOptions]);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref 
        layout="vertical"
        initialValues={{
          ...shopData,
        }}
      >
        <Form.Item
          hasFeedback
          label={`Country/Region`}
          rules={[
            {
              required: false,
              message: "Region is required!",
            },
          ]}
          name="countryId"
        >
          <Select
            allowClear
            showSearch
            placeholder="Select Country"
            optionFilterProp="children"
            onChange={(e) => setCountryId(e)}
            onSearch={(val) => {
              handleSearch?.(val, "country");
            }}
            filterOption={() => {
              return true;
            }}
            options={countryOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          label={`State/Province`}
          rules={[
            {
              required: false,
              message: "State is required!",
            },
          ]}
          name="stateId"
        >
          <Select
            allowClear
            showSearch
            placeholder="Select State"
            optionFilterProp="children"
            onChange={(val) => setStateId(val)}
            onSearch={(val) => {
              handleSearch?.(val, "state");
            }}
            filterOption={() => {
              return true;
            }}
            options={stateOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          label={`City`}
          rules={[
            {
              required: false,
              message: "City is required!",
            },
          ]}
          name="cityId"
        >
          <Select
            allowClear
            showSearch
            placeholder="Select City"
            optionFilterProp="children"
            onChange={(val) => setCityId(val)}
            onSearch={(val) => {
              handleSearch?.(val, "city");
            }}
            filterOption={() => {
              return true;
            }}
            options={cityOptions?.list}
          ></Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Address"
          rules={[
            {
              required: false,
              message: "Address is Required!",
            },
          ]}
          name="address"
        >
          <Input id="address" type="text" placeholder="Enter Name" />
        </Form.Item>

        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            type="ghost"
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddShopAddress;
