import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Empty,
  Form,
  Popconfirm,
  Select,
  Skeleton,
  Tabs,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue } from "../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleAddress from "./Details";
import AddShopAddress from "./AddShopAddress";
import moment from "moment";
import React from "react";
import DirectoryTree from "antd/es/tree/DirectoryTree";
import { AddBtn, Loader, Pagination } from "../common";
import _ from "lodash";
import AddRate from "../common/AddRate";
import { Edit } from "../icons";

const ShopDeliveryList = (): ReactElement => {
  const { type, shopInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const shopId = shopInfo?.id;
  const [form] = Form.useForm();
  const loc = useLocation();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedZone, setSelectedZone] = useState(undefined);
  const [selectedShopAddressForEdit, setSelectedShopAddressForEdit] = useState(undefined);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [zoneType, setZoneType] = useState<any>("DOMESTIC");
  const [shopDeliveryData, setShopDeliveryData] = useState<any>({
    loading: false,
    data: null,
  });
  const [singleShopInfo, setSingleShopInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };

  const fetchShopDetails = useCallback((shopId: any) => {
    if (shopId) {
      try {
        setSingleShopInfo({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_CATALOG_READER_API}/shop/details?shopId=${shopId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setSingleShopInfo({
                loading: false,
                data: data?.data?.shop,
              });
            } else {
              setSingleShopInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleShopInfo({ loading: false, data: null });

            console.log(err);
          });
      } catch (error) {
        setSingleShopInfo({ loading: false, data: null });

        console.log(error, "error");
      }
    }
  }, []);

  const getDelivery = useCallback(async () => {
    setShopDeliveryData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(`${encodedUri}/admin/pickup-zone/all?shopId=${shopId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setShopDeliveryData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setShopDeliveryData({ loading: false, data: [] });
        console.error("Zones: Error", err);
      });
  }, []);

  useEffect(() => {
    fetchShopDetails(shopId);
  }, [fetchShopDetails]);

  const shop = singleShopInfo?.data;
  useEffect(() => {
    getDelivery();
  }, [getDelivery]);

  const onClose = () => {
    getDelivery();
    setVisible(undefined);
    setSelectedZone(undefined);
    setSelectedShopAddressForEdit(undefined);
    setReArrangeDrawer(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  const reseAllFieldData = () => {
    form.resetFields();
    setZoneType("DOMESTIC");
  };

  return (
    <>
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Your Shipping Rates" key="1">
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Country</th>
                        <th scope="col">City</th>
                        <th scope="col">Address</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{shop?.country}</td>
                        <td>{shop?.city}</td>
                        <td>{shop?.address}</td>
                        <td>
                          <button 
                            onClick={(e) => {
                              e.preventDefault();
                              e?.stopPropagation();
                              setSelectedShopAddressForEdit(shop)
                            }}
                            className="px-2"
                          >
                            <Edit height={14} width={14} />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>

        <Pagination
          {...shopDeliveryData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Shop Address"
            : selectedShopAddressForEdit
            ? "Edit Shop Address"
            : "Shop Address Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedZone || selectedShopAddressForEdit}
        width={450}
      >
        {visible || selectedShopAddressForEdit ? (
          <AddShopAddress
            onCloseMethod={onClose}
            visibleData={selectedShopAddressForEdit}
          />
        ) : (
          <SingleAddress zoneDetails={selectedZone} />
        )}
      </Drawer>
    </>
  );
};

export default ShopDeliveryList;
